import React from "react";

import { ConnectionComponent, baseUrl } from '../../utils/connection';

import { HeroContainer, HeroContent } from "../atoms/containers";
import BackgroundImage from "../atoms/backgroundImage";
import { HeroH1 } from "../atoms/h1";
import { HeroP } from "../atoms/p";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  ServicesH1
} from "../ResourcesPage/ServicesElements";

const HeroSection = () => {
    const hero = ConnectionComponent('Hero');
  
    const carousel = ConnectionComponent('Carousel?sort=orden');

    const arrowStyles = {
      position: 'absolute',
      zIndex: 2,
      top: 'calc(50% - 15px)',
      backgroundColor: 'transparent',
      border: 0,
      width: 30,
      height: 30,
      cursor: 'pointer',
    };

    return hero.length > 0 ?
<Carousel 
            autoPlay 
            showThumbs={false} 
            showStatus={false} 
            showArrows={true} 
            infiniteLoop={true} 
            emulateTouch={true} 
            useKeyboardArrows={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button type="button" className='left' onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: '3rem' }}>
                  <span className="arrow first next "></span>
                  <span className="arrow second next "></span>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button type="button" className='right' onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: '3rem' }}>
                  <span className="arrow first next "></span>
                  <span className="arrow second next "></span>
                </button>
              )
            }
          >
        <HeroContainer>
            <BackgroundImage>
                { hero[0].Fondo_Pantalla &&
                    <img src={`${baseUrl}/assets/${hero[0].Fondo_Pantalla}`} alt="background-hero" />
                }
            </BackgroundImage>
            <HeroContent
            >
                <HeroH1
                    color="#FFF"
                    fontSize="2rem"
                    textAlign="center"
                >{hero.length > 0 ? hero[0].Titulo : ""}</HeroH1>
                <HeroP>
                    {hero.length > 0 ? hero[0].Subtitulo : ""}
                </HeroP>
            </HeroContent>
        </HeroContainer>
 
            {carousel.length > 0 ?
              carousel.map(image =>
                <div key={`div-${image.id}`} style={{display: 'flex' ,height:'25rem', marginTop: '5rem'}} onClick={() => {
                  return !image['texto_boton'] ? window.open(image.enlace, "_blank") : null;
                }}>
                  <div className='textCar'>
                    <ServicesH1 className='h1text'>{image.titulo}</ServicesH1>
                    {image['texto_boton'] ? <a className='btnL1' style={{ backgroundColor: '#50838b', marginBottom: '2rem' }} href={image.enlace} target="_blank" rel="noreferrer">{image['texto_boton']}</a> : null}
                  </div>
                  <img alt={`carrousel-${image.id}`} src={`${baseUrl}/assets/${image['imagen']}`} className='imgCarousel' />
                </div>
              )
              : <></>}
          </Carousel>
       : <div></div>
};

export default HeroSection;
