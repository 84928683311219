import styled from 'styled-components';

export const Img = styled.img`
  width: 85vh;
  margin-left: -4rem;

  @media screen and (max-width: 860px) {
    width: 35vh;
    margin-left: 0;
  }
`;

export const ArrowImg = styled.img`
  width: 10rem;

  @media screen and (max-width: 860px) {
    width: 8rem;
  }
`;

export const Logos = styled.img`
  width: 15rem;

  @media screen and (max-width: 480px) {
    width: 10rem;
  }
`;

export const CarouselImg = styled.img`

`;
