import styled from 'styled-components';

export const ServicesContainer = styled.div`
  display: list-item;
  background: #FFF;
  margin-bottom: 3rem;
  margin-top: 3rem;
`;

export const pTest = styled.p`
  font-size: 1rem;
  text-align: justify;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;


export const ServicesP = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #FFF;
  margin: 1rem 5rem;
  text-align: justify;

  @media screen and (max-width: 768px) {
    margin: 1rem 2rem;
    font-size: 0.8rem;
  }
`;

export const ServicesPa = styled.p`
  font-size: 1rem;
  font-weight: normal;
  color: #444;
  margin: 1rem 5rem;
  text-align: justify;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const ServicesUl = styled.ul`
  list-style: none;
`;

export const ServicesLi = styled.li`
  font-size: 1rem;
  font-weight: 500;
  color: #FFF;
  margin: 1rem 6rem;
  text-align: justify;

  ::before {
    content: "-";
    color: #48898a;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }

  @media screen and (max-width: 768px) {
    margin: 1rem 2rem;
    font-size: 0.8rem;
  }
`;

export const ServicesWrapper = styled.div`
  margin-block: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ServicesWrapperTitle = styled.div`
  display: block;
  align-items: center;
  padding: 0 50px;
`;

export const ServicesBar1 = styled.div`
  width: 100%;  
  border-bottom: 4px solid #479197;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;


export const ServicesBar = styled.div`
  width: 6.5rem;  
  border-bottom: 4px solid #479197;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const ServicesBar2 = styled.div`
  width: 6.5rem;  
  border-bottom: 4px solid #479197;
  margin-top: 1.5rem;
  margin-bottom: -3rem;
`;

export const ServicesH1 = styled.h1`
  font-size: 2rem;
  color: #000;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const Arrow = styled.div`
.right {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}

.left {
  margin-left: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  transform:scaleX(-1);
}

.arrow {position: absolute; bottom: 0;  margin-left:0px; width: 2rem; height: 2rem; background-size: contain; top:15px;}
.second{margin-left: 1rem;}
.next {
  opacity: 0.6;
background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}

@keyframes bounceAlpha {
0% {opacity: 1; transform: translateX(0px) scale(1);}
25%{opacity: 0; transform:translateX(10px) scale(0.9);}
26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
55% {opacity: 1; transform: translateX(0px) scale(1);}
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

.arrow.first.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-delay:0.2s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

.right:hover .arrow{
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}
.right:hover .arrow.first{
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-delay:0.2s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

.left:hover .arrow{
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}
.left:hover .arrow.first{
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-delay:0.2s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

@media screen and (max-width: 768px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(100px, auto);
  }
}

.test {
  width: 80vw;
  height: 12vw;
  object-fit: cover;
}

.country-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.country-logo {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  padding-bollck: 0.5rem;
}

.selected-logo {
  width: 35px;
  height: 20px;
}


.dot {
  height: 0.8rem;
  width: 0.8rem;
  margin-inline: 0.3rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.container {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 8rem;
  transform: translate(-50%, -50%);
}
.center {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}


body {
	font-family: "Spline Sans", sans-serif;
	line-height: 1.5;
	min-height: 100vh;
	background-color: var(--c-ash);
}

.cards {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	gap: 2.5rem;
	width: 90%;
	max-width: 1000px;
	margin: 4vh auto;
}

.card {
	border-radius: 16px;
	box-shadow: 0 30px 30px -25px rgba(#4133B7, .25);
	max-width: 300px;
}

.btnHo:hover {
  background-color: #2d5859 !important;
}

.information {
	background-color: white;
	padding: 2.5rem;

	.title {
		font-size: 1.5rem;
		color: var(--c-void);
		line-height: 1.25;
    min-height: 8rem;
		& + * {
			margin-top: 1rem;
		}
	}
	
	.details {
		display: flex;
		gap: 1rem;
    min-height: 8rem;
		div {
			border-radius: 8px;
			display: flex;
			flex-direction: column-reverse;
			gap: .125em;
			flex-basis: 50%;
		}
	}
}
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5rem;
  padding: 0 24px;
  width: 90%;
  justify-content: center;
`;
