import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import flecha from "../../images/FlechaBoton3.svg";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  ContainerForm,
  FormContainer,
  SubContainerB,
} from "../atoms/containers";
import { FormInputsResponsive, ServiceTitleForm } from "../atoms/wrappers";
import { ServicesH1 } from "../atoms/h1";
import { FormBar, FormBarA } from "../atoms/bars";
import { FormArea, FormInputLarge } from "../atoms/forms";
import { ServicesButton, ServicesLinkB } from "../atoms/buttons";
import { FormP } from "../atoms/p";
import Collapsible from "react-collapsible";
import Checkbox from "../atoms/Checkbox";
import "./css.css";

const CollapsibleOption = (opts) => {
  const [fields, setFields] = useState(opts.options);
  const [customField, setCustomField] = useState();

  const handleOnChange = async (position, el) => {
    const updatedCheckedState = fields.map((item, index) => {
      if (opts.type === "checkbox") {
        item.isChecked = index === position ? !item.isChecked : item.isChecked;
      } /* radio */ else {
        item.isChecked = index === position ? !item.isChecked : false;
      }
      return item;
    });

    // Detects with the position 99 the text inputs
    if (position === 99) {
      const newFields = [...opts.options];
      const indexMax = opts.options.length + 1;

      newFields[indexMax] = {
        label: el.target.value,
        value: el.target.value,
        isChecked: true,
      };

      if (el.target.value.trim() === "") {
        delete newFields[indexMax];
      }

      setFields(newFields);
    } else {
      setFields(updatedCheckedState);
    }

    await opts.setter((values) => ({
      ...values,
      [opts.keyOption]: fields,
    }));
  };

  return (
    <Collapsible
      open={opts.open ? opts.open : false}
      classParentString="gapOption"
      trigger={`${opts.label}`}
      triggerClassName={`${opts.classOption}`}
      triggerOpenedClassName={`${opts.classOption}`}
      contentInnerClassName={`${opts.classOption}Content`}
    >
      <div
        style={{
          paddingBottom: typeof opts.messageJsx != "boolean" ? "1.5rem" : "0",
        }}
      >
        {typeof opts.messageJsx != "boolean" && opts.messageJsx}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {fields.map((element, index) => {
          return (
            <label key={`opt-${index}`} style={{ padding: "1rem" }}>
              <Checkbox
                checked={fields[index].isChecked}
                onChange={() => handleOnChange(index)}
                color={opts.classOption === "option" ? "#479197" : "#6e2f85"}
              />
              <span style={{ marginInline: "1rem", fontWeight: 500 }}>
                {element ? element.label : ""}
              </span>
              {element && element.message && (
                <div style={{ paddingBlock: "1rem" }}>
                  {element ? element.message : ""}
                </div>
              )}
            </label>
          );
        })}
      </div>
      {opts.otherField && (
        <FormInputsResponsive>
          <label>{opts.otherLabel}</label>
          <FormInputLarge
            type="text"
            onChange={(el) => handleOnChange(99, el)}
          />
        </FormInputsResponsive>
      )}
      {opts.customExtraKey && (
        <FormInputsResponsive>
          <label>{opts.customExtraLabel}</label>
          <FormInputLarge
            type="text"
            onChange={async (el) => {
              setCustomField(el.target.value);
              await opts.setter((values) => ({
                ...values,
                [opts.customExtraKey]: customField,
              }));
            }}
          />
        </FormInputsResponsive>
      )}
    </Collapsible>
  );
};

const FormServices = () => {
  const [inputs, setInputs] = useState();
  const MySwal = withReactContent(Swal);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    const serviceID = query.get("refererID");
    switch (parseInt(serviceID)) {
      case 1:
        setInputs((values) => ({
          ...values,
          Servicio_solicitado:
            "Comunicaciones estratégicas para construir poder narrativo",
        }));
        break;
      case 2:
        setInputs((values) => ({
          ...values,
          Servicio_solicitado: "Cumplimiento legal",
        }));
        break;
      case 3:
        setInputs((values) => ({
          ...values,
          Servicio_solicitado:
            "Pensamiento estratégico y resiliencia financiera",
        }));
        break;
      case 4:
        setInputs((values) => ({
          ...values,
          Servicio_solicitado: "Protección integral",
        }));
        break;
      default:
        break;
    }
  }, [query]);

  const terminos_condiciones = (
    <div>
      <p>
        <b>
          Te recordamos que los servicios que brinda la iniciativa son gratuitos
          y únicamente se puede solicitar uno a la vez.
        </b>
      </p>
      <p style={{ paddingBlock: "1rem" }}>
        Antes de comenzar el formulario, te invitamos a confirmar los siguientes{" "}
        <b>
          términos para definir si tu organización es candidata para recibir
          nuestros servicios.
        </b>
      </p>
      <ul className="listTerms">
        <li>Promueve discursos o prácticas de los derechos humanos.</li>
        <li>Es apartidista y sin fines de lucro.</li>
        <li>Se encuentra en México o Centroamérica.</li>
        <li>
          No se encuentra en alguna situación en la que haya sospechas de
          corrupción, discriminación, violencia basada en género; que promuevan
          o inciten acciones violentas; o en situación de conflicto interno
          grave.
        </li>
        <li>
          Cuenta con una persona o equipo con disponibilidad para comprometerse
          con los objetivos y requerimientos de tiempo de los servicios
          solicitados.
        </li>
        <li>
          Entiendo que se requiere compromiso, tiempo y disponibilidad de mi
          parte y de mi organización para alcanzar los objetivos del proceso.
        </li>
      </ul>
      <p style={{ paddingBlock: "1rem" }}>
        Si consideras que tu organización cumple con los términos y es candidata
        para recibir nuestros servicios, marca la casilla a continuación:
      </p>
    </div>
  );

  const handleText = (event) => {
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let validation = Object.values(inputs).filter((value) => {
      let valueWithData = false;
      if (typeof value === "object" && value.length >= 1) {
        valueWithData = value;
      }

      if (value.length >= 1) {
        valueWithData = value;
      }

      return valueWithData;
    });

    if (!inputs["Terminos_y_condiciones"]) {
      Swal.fire({
        icon: "info",
        title: "Falta la confirmación de los términos",
        text: "Es necesario aceptar los términos para continuar con su solicitud.",
      });
    } else if (validation.length < 12) {
      let validation_to = "Tipo_de_organizacion" in inputs ? '' : '<p>4.- Tipo de organización</p>';
      let validation_p = "Pais" in inputs ? '' : '<p>5.- País en el que realizan la mayor parte de su trabajo</p>';
      let validation_e = "Ecosistema" in inputs ? '' : '<p>6.- Ámbito de promoción de los derechos humanos que trabaja la organización</p>';
      let validation_s = "Servicio_solicitado" in inputs ? '' : '<p>7.- Por favor, elige el servicio que requieres ...</p>';
      let validation_m = "Motivo_de_solicitud" in inputs ? '' : '<p>8.- ¿Cuál es el motivo que te lleva a solicitar nuestros servicios? ...</p>';
      let validation_f = "Fundacion" in inputs ? '' : '<p>10.- De la siguiente lista de instituciones internacionales ... </p>';
      let validation_v = "Verificacion_de_consentimiento" in inputs ? '' : '<p>11.- Casilla de verificación de este consentimiento informado</p>';
      
      let arrVal = [validation_to, validation_p, validation_e, validation_s, validation_m, validation_f, validation_v];

      Swal.fire({
        icon: "info",
        title: "Por favor revise sus datos",
        html: "<h4>Todos los campos son obligatorios.</h4><br/> <p>Verifique los siguientes campos:</p>" + arrVal.join(' ').trim(),
      });
    } else if (
      !inputs["Verificacion_de_consentimiento"]
    ) {
      Swal.fire({
        icon: "info",
        title: "Verifique su consentimiento",
        text: "Es necesario que marque ambas casillas de verificación del onceavo paso para hacer constar su consentimiento.",
      });
    } else {
      let inputToSend = inputs;

      inputToSend['Terminos_y_condiciones'] = 'Mi organización cumple con las características';
      inputToSend['Aceptacion_compromiso'] = 'Entiendo las implicaciones y las responsabilidades que conlleva el proceso solicitado y me comprometo a colaborar';
      inputToSend['Tipo_de_organizacion'] = typeof inputToSend['Tipo_de_organizacion'] != "string" ? inputToSend['Tipo_de_organizacion'].filter((option) => option.isChecked === true)[0].value : inputToSend['Tipo_de_organizacion'];

      let pais = inputToSend['Pais'].filter((option) => option.isChecked === true);
      inputToSend['Pais'] = pais.map((option) => option.value);

      let ecosistema = inputToSend['Ecosistema'].filter((option) => option.isChecked === true);
      inputToSend['Ecosistema'] = ecosistema.map((option) => option.value);

      inputToSend['Servicio_solicitado'] = typeof inputToSend['Servicio_solicitado'] != "string" ? inputToSend['Servicio_solicitado'].filter((option) => option.isChecked === true)[0].value : inputToSend['Servicio_solicitado'];
      inputToSend['Motivo_de_solicitud'] = typeof inputToSend['Motivo_de_solicitud'] != "string" ? inputToSend['Motivo_de_solicitud'].filter((option) => option.isChecked === true)[0].value : inputToSend['Motivo_de_solicitud'];

      let fundacion = inputToSend['Fundacion'].filter((option) => option.isChecked === true);
      inputToSend['Fundacion'] = fundacion.map((option) => option.value);

      let verificacion = 
        inputToSend['Verificacion_de_consentimiento'].length >= 0 ? inputToSend['Verificacion_de_consentimiento'][0].isChecked === true && inputToSend['Verificacion_de_consentimiento'][0].value === 'Autorizo el tratamiento de mis datos personales según los términos y condiciones' ?  ['Hago constar que leí el Aviso de Privacidad','Autorizo el tratamiento de mis datos personales según los términos y condiciones'] : []: [];
      inputToSend['Verificacion_de_consentimiento'] = verificacion;
      
      axios
        .post(`${baseUrl}/items/Respuestas`, inputToSend)
        .then((response) => {
          if (response) {
            MySwal.fire({
              icon: "success",
              title: "Gracias por contactarnos",
              text: "¡Tu solicitud ha sido enviada con éxito! Recibimos tu solicitud y responderemos a la brevedad. Agradecemos tu paciencia.",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "El formulario ha fallado en enviarse.",
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El formulario ha fallado en enviarse.",
          });
        });
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ContainerForm>
        <SubContainerB>
          <ServiceTitleForm>
            <ServicesH1>SOLICITUD DE SERVICIOS</ServicesH1>
            <FormBar />
          </ServiceTitleForm>
        </SubContainerB>
        <FormContainer onSubmit={handleSubmit} style={{ color: "black" }}>
        <CollapsibleOption
        open={true}
          label="Términos y condiciones"
          options={[
            {
              label:
                "Mi organización, red o colectiva es candidata para recibir los servicios de ECOS",
              value: "Acepto los términos",
              isChecked: false,
            },
          ]}
          classOption="featuredOption"
          keyOption="Terminos_y_condiciones"
          setter={setInputs}
          // Aceptacion_compromiso
          messageJsx={terminos_condiciones}
          type="radio"
        />
          <FormBarA />
          <div>
            <FormInputsResponsive>
              <label>1.- Nombre de quien registra</label>
              <FormInputLarge
                type="text"
                required
                name="Nombre"
                onChange={(ev) => handleText(ev)}
              />
            </FormInputsResponsive>
            <FormInputsResponsive>
              <label>2.- Nombre de la organización</label>
              <FormInputLarge
                type="text"
                required
                name="Organizacion"
                onChange={(ev) => handleText(ev)}
              />
            </FormInputsResponsive>
            <FormInputsResponsive>
              <label>3.- Correo electrónico</label>
              <FormInputLarge
                type="text"
                required
                name="Contacto"
                onChange={(ev) => handleText(ev)}
              />
            </FormInputsResponsive>
            <CollapsibleOption
              label="4.- Tipo de organización"
              options={[
                {
                  label: "Colectiva",
                  value: "Colectiva",
                  isChecked: false,
                },
                {
                  label: "Red",
                  value: "Red",
                  isChecked: false,
                },
                {
                  label:
                    "Organización de la Sociedad Civil legalmente constituida",
                  value:
                    "Organización de la Sociedad Civil legalmente constituida",
                  isChecked: false,
                },

                {
                  label: "Activista / Defensora independiente",
                  value: "Activista / Defensora independiente",
                  isChecked: false,
                },
              ]}
              classOption="option"
              messageJsx={false}
              keyOption="Tipo_de_organizacion"
              setter={setInputs}
              type="radio"
            />
            <CollapsibleOption
              label="5.- País en el que realizan la mayor parte de su trabajo"
              options={[
                {
                  label: "México",
                  value: "México",
                  isChecked: false,
                },
                {
                  label: "Guatemala",
                  value: "Guatemala",
                  isChecked: false,
                },
                {
                  label: "El Salvador",
                  value: "El Salvador",
                  isChecked: false,
                },

                {
                  label: "Honduras",
                  value: "Honduras",
                  isChecked: false,
                },
              ]}
              classOption="option"
              messageJsx={false}
              type="checkbox"
              keyOption="Pais"
              setter={setInputs}
              customExtraLabel="Ciudad"
              customExtraKey="Ciudad"
              otherField={true}
              otherLabel="Otro (y especificar cuál)"
            />
            <CollapsibleOption
              label="6.- Ámbito de promoción de los derechos humanos  que trabaja la organización"
              options={[
                {
                  label: "Derechos humanos y población en movilidad",
                  value: "Derechos humanos y población en movilidad",
                  isChecked: false,
                },
                {
                  label: "Juventud",
                  value: "Juventud",
                  isChecked: false,
                },
                {
                  label: "Organizaciones LGBTTTIQ+",
                  value: "Organizaciones LGBTTTIQ+",
                  isChecked: false,
                },
                {
                  label: "Movimientos feministas y derechos de las mujeres",
                  value: "Movimientos feministas y derechos de las mujeres",
                  isChecked: false,
                },
                {
                  label: "Justicia ambiental, protección y conservación",
                  value: "Justicia ambiental, protección y conservación",
                  isChecked: false,
                },
                {
                  label: "Comunidades en defensa de los territorios",
                  value: "Comunidades en defensa de los territorios",
                  isChecked: false,
                },
                {
                  label:
                    "Organizaciones y movimientos indígenas y afrodescendientes",
                  value:
                    "Organizaciones y movimientos indígenas y afrodescendientes",
                  isChecked: false,
                },
                {
                  label: "Lucha contra la corrupción y la impunidad",
                  value: "Lucha contra la corrupción y la impunidad",
                  isChecked: false,
                },
                {
                  label: "Libertad de expresión y periodismo",
                  value: "Libertad de expresión y periodismo",
                  isChecked: false,
                },
                {
                  label: "Todos los anteriores",
                  value: "Todos los anteriores",
                  isChecked: false,
                },
              ]}
              keyOption="Ecosistema"
              setter={setInputs}
              classOption="option"
              messageJsx={false}
              type="checkbox"
              otherField={true}
              otherLabel="Otro"
            />
            <CollapsibleOption
              label="7.-  Por favor, elige el servicio que requieres (solo se permite seleccionar uno)"
              options={[
                {
                  label:
                    "Entrenamiento en poder narrativo y comunicación estratégica - Inspiratorio",
                  value:
                    "Comunicaciones estratégicas para construir poder narrativo",
                  isChecked: false,
                  message: (
                    <p>
                      ¡Únete al{" "}
                      <a
                        href="http://www.inspiratorio.org"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Inspiratorio
                      </a>
                      ! Somos el espacio de entrenamiento de{" "}
                      <a
                        href="http://www.somos-puentes.org"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Puentes
                      </a>
                      . Invitamos a organizaciones, activistas y agentes de
                      cambio de Latinoamérica a ejercitar su poder narrativo.
                      Queremos que fortalezcas tu músculo de trabajo colectivo,
                      creando historias y mensajes que inspiren acciones hacia
                      un mundo más justo, empático y basado en la dignidad, el
                      cuidado y la liberación. A través de nuestras
                      convocatorias, ofrecemos becas y oportunidades de
                      formación en grupo para activistas y organizaciones en
                      toda la región. Si formas parte de Ecos, puedes solicitar
                      un acompañamiento personalizado con aliadas expertas para
                      que empieces a tejer narrativas desde tu organización. Usa
                      este formulario para solicitar tu invitación a nuestra
                      lista de correos y únete a la transformación. ¡Vamos a
                      cambiar la historia!
                    </p>
                  ),
                },
                {
                  label:
                    "Asistencia y acompañamiento en cumplimiento legal - Creatura",
                  value: "Cumplimiento legal",
                  isChecked: false,
                  message: (
                    <p>
                      En{" "}
                      <a
                        href="https://creatura.mx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Creatura
                      </a>{" "}
                      queremos contribuir a la resiliencia de las organizaciones
                      de la sociedad civil, la disminución de vulnerabilidades
                      legales, y la continuidad de su proyecto de cambio social.
                      A partir de las necesidades concretas en cuestiones de
                      cumplimiento fiscal, laboral, corporativo, internacional o
                      administrativo, ofrecemos servicios de maneral universal,
                      como de manera colectiva y/o individual, que fortalezcan
                      su capacidad de respuesta ante los constantes cambios del
                      marco legal, de las características del sector y el
                      contexto político regional, dando asistencia en materia de
                      cumplimiento legal.
                    </p>
                  ),
                },
                {
                  label:
                    "Acompañamientos en pensamiento estratégico y resiliencia financiera - Cometa",
                  value: "Pensamiento estratégico y resiliencia financiera",
                  isChecked: false,
                  message: (
                    <p>
                      En{" "}
                      <a
                        href="https://www.colectivometa.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Cometa
                      </a>{" "}
                      ofrecemos estos acompañamientos por separado. Desde
                      diferentes perspectivas, ambos buscan fortalecer la
                      capacidad de adaptación estratégica y resiliencia
                      institucional de organizaciones de la sociedad civil
                      comprometidas con la transformación social, para que
                      puedan aumentar su potencial de impacto y alcanzar los
                      cambios que se proponen. Los acompañamientos se ofrecen de
                      forma individual, sobre la base de un diagnóstico
                      institucional, y en el proceso se atienden las necesidades
                      concretas y las circunstancias específicas de cada
                      organización.
                    </p>
                  ),
                },

                {
                  label:
                    "Apoyos, acompañamientos y formaciones en protección integral - Fundación Acceso",
                  value: "Protección integral",
                  isChecked: false,
                  message: (
                    <p>
                      En{" "}
                      <a
                        href="https://www.acceso.or.cr"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Fundación Acceso
                      </a>{" "}
                      apostamos por una mirada apegada al resguardo y a la
                      protección de los derechos humanos, atendiendo distintos
                      aspectos, como la seguridad física, la seguridad
                      psicosocial, la seguridad legal y la seguridad digital,
                      entre otros. Nuestro concepto de protección integral se
                      aleja de las visiones tradicionales de la seguridad,
                      comúnmente vinculadas al control social o al recorte de
                      derechos civiles. Con ello, buscamos mejorar el bienestar
                      y la resiliencia colectiva en las organizaciones de la
                      sociedad civil.
                    </p>
                  ),
                },
              ]}
              classOption="option"
              setter={setInputs}
              keyOption="Servicio_solicitado"
              messageJsx={
                <p>
                  ECOS es una iniciativa colaborativa formada por cuatro
                  organizaciones, cada una experta en un servicio que
                  fortalecerá tu resiliencia institucional: Inspiratorio,
                  Creatura, Cometa y Fundación Acceso. A continuación la
                  descripción de los servicios que brindan:
                </p>
              }
              type="radio"
            />
            <CollapsibleOption
              label="8.- ¿Cuál es el motivo que te lleva a solicitar nuestros servicios? (Selecciona las opciones que apliquen)"
              options={[
                {
                  label:
                    "La organización atraviesa por una situación de riesgo",
                  value:
                    "La organización atraviesa por una situación de riesgo",
                  isChecked: false,
                },
                {
                  label: "La organización quiere prevenir riesgos",
                  value: "La organización quiere prevenir riesgos",
                  isChecked: false,
                },
                {
                  label:
                    "La organización se encuentra en un proceso de reestructuración",
                  value:
                    "La organización se encuentra en un proceso de reestructuración",
                  isChecked: false,
                },
                {
                  label:
                    "La organización necesita actualizar sus procesos institucionales",
                  value:
                    "La organización necesita actualizar sus procesos institucionales",
                  isChecked: false,
                },

                {
                  label:
                    "La organización trabaja constantemente en su fortalecimiento interno",
                  value:
                    "La organización trabaja constantemente en su fortalecimiento interno",
                  isChecked: false,
                },
              ]}
              setter={setInputs}
              keyOption="Motivo_de_solicitud"
              classOption="option"
              messageJsx={false}
              type="checkbox"
              otherField={true}
              otherLabel="Otro (abierto)"
            />
            <p>
              <label style={{ fontWeight: 500 }}>
                9.- ¿Cuáles son las principales necesidades de tu organización
                en relación con el servicio que solicitaste? (300 palabras)
              </label>
              <FormArea
                type="text"
                required
                name="Necesidades"
                onChange={(ev) => handleText(ev)}
              />
            </p>
            <CollapsibleOption
              label="10.- De la siguiente lista de instituciones internacionales, ¿alguna es donante de tu organización?"
              options={[
                {
                  label: "David and Lucile Packard Foundation",
                  value: "David and Lucile Packard Foundation",
                  isChecked: false,
                },
                {
                  label: "Ford Foundation",
                  value: "Ford Foundation",
                  isChecked: false,
                },
                {
                  label: "Foundation for a Just Society",
                  value: "Foundation for a Just Society",
                  isChecked: false,
                },
                {
                  label: "The William and Flora Hewlett Foundation",
                  value: "The William and Flora Hewlett Foundation",
                  isChecked: false,
                },
                {
                  label: "Hispanics in Philanthropy",
                  value: "Hispanics in Philanthropy",
                  isChecked: false,
                },
                {
                  label: "Oak Foundation",
                  value: "Oak Foundation",
                  isChecked: false,
                },
                {
                  label: "Open Society Foundations",
                  value: "Open Society Foundations",
                  isChecked: false,
                },
                {
                  label: "Rockefeller Brothers Fund",
                  value: "Rockefeller Brothers Fund",
                  isChecked: false,
                },
                {
                  label: "Seattle International Foundation",
                  value: "Seattle International Foundation",
                  isChecked: false,
                },
                {
                  label: "The Summit Foundation",
                  value: "The Summit Foundation",
                  isChecked: false,
                },
                {
                  label: "W.K. Kellogg Foundation",
                  value: "W.K. Kellogg Foundation",
                  isChecked: false,
                },
                {
                  label: "Ninguna de las anteriores",
                  value: "Ninguna de las anteriores",
                  isChecked: false,
                },
                {
                  label: "Mi organización no recibe donaciones de algún tipo",
                  value: "Mi organización no recibe donaciones de algún tipo",
                  isChecked: false,
                },
              ]}
              classOption="option"
              type="checkbox"
              setter={setInputs}
              keyOption="Fundacion"
              otherField={true}
              messageJsx={<p>
                <b>
                  Puedes solicitar servicios de Ecos aunque no recibas
                  financiación de las siguientes fundaciones.
                </b>
              </p>}
              otherLabel="Otro (abierta)"
            />
            <CollapsibleOption
              label="11.- Casilla de verificación de este consentimiento informado"
              options={[
                {
                  label:
                    "Autorizo el tratamiento de mis datos personales según los términos y condiciones.",
                  value:
                    "Autorizo el tratamiento de mis datos personales según los términos y condiciones",
                  isChecked: false,
                },
              ]}
              keyOption="Verificacion_de_consentimiento"
              classOption="featuredOption"
              setter={setInputs}
              messageJsx={<div>
                <p>
                  <b>Hago constar que leí el Aviso de Privacidad</b>
                </p>
              </div>}
              type="radio"
            />

            <ServicesButton onClick={() => handleSubmit}>
              <ServicesLinkB style={{ color: "black" }}>
                ENVIAR
                <img src={flecha} alt="Enviar Solicitud" />
              </ServicesLinkB>
            </ServicesButton>
          </div>
          <div style={{ marginBottom: "5rem", marginTop: "5rem" }}>
            <FormP style={{ color: "black" }}>
              <b>AVISO DE PRIVACIDAD FORMULARIO SOLICITUD DE SERVICIOS</b>
            </FormP>
            <FormP style={{ color: "black" }}>
              Creatura, Promotora del Pensamiento Crítico, S.C. con domicilio en
              Popocatepetl 18, Hipódromo, Cuauhtémoc, 06100, Ciudad de México;
              es la responsable del buen uso de la información que usted está
              proporcionando sobre la organización que representa. Sus datos
              serán utilizados con la finalidad de canalizar a su organización a
              la prestadora de servicios de ECOS más adecuada (Acceso, Creatura,
              Cometa o Puentes - Inspiratorio), por lo que la información aquí
              proporcionada será compartida exclusivamente entre las
              organizaciones de ECOS con el fin de poder brindar los servicios
              solicitados por el usuario. La información proporcionada es
              indispensable para que alguna organización de ECOS pueda tomar
              decisiones en razón de la prestación de servicios, así como enviar
              comunicaciones referentes al servicio solicitado, actividades y
              eventos relacionados con ECOS. Usted puede hacer valer sus
              derechos de Acceso, Rectificación, Cancelación u Oposición,
              enviando un correo a contacto@creatura.mx.
            </FormP>
          </div>
        </FormContainer>
      </ContainerForm>
    </div>
  );
};

export default FormServices;
